import * as THREE from "three";
import { useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useEffect, useRef } from "react";
import { extendMaterial, CustomMaterial } from "../extend";

const triMeshShaderMaterial = extendMaterial(THREE.MeshMatcapMaterial, {
  class: CustomMaterial,
  vertexHeader: `
        attribute float aRandom;
        attribute vec3 aCenter;
      uniform float uTime;
      uniform float uProgress;
      uniform float uSpeed;
      uniform float uScale;

      mat4 rotation3d(vec3 axis, float angle) {
        axis = normalize(axis);
        float s = sin(angle);
        float c = cos(angle);
        float oc = 1.0 - c;
      
        return mat4(
          oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
          oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
          oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
          0.0,                                0.0,                                0.0,                                1.0
        );
      }

      vec3 rotate(vec3 v, vec3 axis, float angle) {
        mat4 m = rotation3d(axis, angle);
        return (m * vec4(v, 1.0)).xyz;
      }

      
  `,
  vertex: {
    transformEnd: `


    float progress = clamp((position.y + 1.0)/2.,0.,1.); 
    float localProgress = clamp((uProgress - 0.5*progress)/0.2, 0., 1.);




    // transformed = transformed - aCenter;
    // transformed += uScale*normal*aRandom*localProgress;
    // transformed *=clamp((1.- localProgress*9.),0.,1.);
    // transformed += aCenter;
    // transformed = rotate(transformed, vec3(0.0, 1.0, 0.0), aRandom*(localProgress)*3.14*uScale);









    // 1. get delta to aCenter
    transformed = transformed - aCenter;

    // 2. multiply with local progress
    transformed += normal * aRandom * localProgress;
    transformed *= (1.0 - localProgress);
    transformed += aCenter;

    transformed = rotate(transformed, vec3(0., 1., 0.), aRandom * localProgress * 3.14 * uSpeed);







    // // transformed = transformed - aCenter;

    // // transformed += uScale * normal * aRandom * locprog;
    // // transformed *= clamp((1. - locprog * 5.), 0., 1.);

    // // transformed += aCenter;

    // // // Rotate
    // transformed = rotate(transformed, vec3(0., 1., 0.), aRandom * locprog * 3.14 * uSpeed);


    `,
  },
  fragment: {
    "vec3 outgoingLight = diffuseColor.rgb * matcapColor.rgb;":
      "outgoingLight = texture2D( matcap, uv ).rgb;",
  },

  uniforms: {
    uProgress: { mixed: true, linked: true, value: 0 },
    uSpeed: { mixed: true, linked: true, value: 2.0 },
    uTime: {
      mixed: true,
      linked: true,
      value: 0,
    },
    roughness: 0.75,
    matcap: new THREE.Texture(),
  },
});

const depthMaterial = extendMaterial(THREE.MeshDepthMaterial, {
  template: triMeshShaderMaterial,
});

const TriMeshedObject = ({
  controls,
  geometryObject,
  animationProgress = 0,
  onLoaded,
}) => {
  const matcap = useTexture("/models/ice_furnace.png");
  const meshRef = useRef();

  useEffect(() => {
    // console.log("model loaded");
    onLoaded();
  }, [onLoaded]);

  useEffect(() => {
    let m = meshRef.current;
    // console.log("TriMeshedObject: mounted", m);
    return () => {
      // console.log("TriMeshedObject: unmounted", m);
    };
  }, []);

  // update attribute for shader material
  useEffect(() => {
    const geometry = meshRef.current.geometry;
    meshRef.current.geometry.computeBoundingBox();
    let len = geometry.attributes.position.count;
    let randoms = new Float32Array(len);
    let centers = new Float32Array(len * 3);
    for (let i = 0; i < len; i += 3) {
      let r = Math.random();
      randoms[i] = r;
      randoms[i + 1] = r;
      randoms[i + 2] = r;

      let x = geometry.attributes.position.array[i * 3];
      let y = geometry.attributes.position.array[i * 3 + 1];
      let z = geometry.attributes.position.array[i * 3 + 2];

      let x1 = geometry.attributes.position.array[i * 3 + 3];
      let y1 = geometry.attributes.position.array[i * 3 + 4];
      let z1 = geometry.attributes.position.array[i * 3 + 5];

      let x2 = geometry.attributes.position.array[i * 3 + 6];
      let y2 = geometry.attributes.position.array[i * 3 + 7];
      let z2 = geometry.attributes.position.array[i * 3 + 8];

      let center = new THREE.Vector3(x, y, z)
        .add(new THREE.Vector3(x1, y1, z1))
        .add(new THREE.Vector3(x2, y2, z2))
        .divideScalar(3);

      centers.set([center.x, center.y, center.z], i * 3);
      centers.set([center.x, center.y, center.z], (i + 1) * 3);
      centers.set([center.x, center.y, center.z], (i + 2) * 3);
    }
    geometry.setAttribute("aRandom", new THREE.BufferAttribute(randoms, 1));
    geometry.setAttribute("aCenter", new THREE.BufferAttribute(centers, 3));

    meshRef.current.customDepthMaterial = depthMaterial;
  }, []);

  useEffect(() => {
    meshRef.current.material.uniforms.matcap.value = matcap;
  }, [matcap]);

  useFrame(({ clock }) => {
    meshRef.current.material.uniforms.uTime.value = clock.elapsedTime;
    let progress = 0;
    // const percentage = scroll.current / limit;
    const lowerBound = 0;
    if (animationProgress > lowerBound) {
      progress = (animationProgress - 0.1 - lowerBound) / (1 - lowerBound);
    }
    meshRef.current.material.uniforms.uProgress.value = progress;
  });

  useEffect(() => {
    meshRef.current.material.uniforms.uSpeed.value = controls.speed;
  }, [controls]);

  return (
    <mesh castShadow ref={meshRef} dispose={null}>
      <primitive object={geometryObject} attach="geometry" />
      <primitive
        object={triMeshShaderMaterial}
        attach="material"
        side={THREE.DoubleSide}
      />
      {/* <meshMatcapMaterial side={THREE.DoubleSide} /> */}
    </mesh>
  );
};

export default TriMeshedObject;
