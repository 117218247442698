import { useEffect } from "react";
import HotspotStatue from "./HotspotStatue";
import niceColors from "nice-color-palettes";

const colorSet = niceColors[4];

const HotspotLineUp = ({ items, activeItem, darkMode, disable = true }) => {
  // useEffect(() => {
  //   console.log("hotspot lineup: mounted");
  //   return () => {
  //     console.log("hotspot lineup: unmounted");
  //   };
  // }, []);

  return (
    <group position={[0, -1, 0]}>
      {items.map((i, idx) => (
        <group key={i.id} position={[i.position.x, 0, i.position.z]}>
          <HotspotStatue
            darkMode={darkMode}
            color={colorSet[idx]}
            item={i}
            activeItem={items[activeItem]}
            disable={disable}
          />
        </group>
      ))}
    </group>
  );
};

export default HotspotLineUp;
