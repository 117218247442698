import { Text } from "@react-three/drei";
import { extend, useThree } from "@react-three/fiber";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import { useScene } from "../../contexts/sceneContext";
import GrainShaderMaterial from "../../shaders/Grain";

extend({ GrainShaderMaterial });

const HotspotStatue = ({ item, color, activeItem, disable }) => {
  const ref = useRef();
  const groupRef = useRef();
  const { sceneConfig } = useScene();
  const meshRef = useRef();
  const textRef = useRef();
  const { camera } = useThree();

  // useEffect(() => {
  //   console.log("HotspotStatue: mounted");
  //   return () => {
  //     console.log("HotspotStatue: unmounted");
  //   };
  // }, []);

  useEffect(() => {
    const isActive = activeItem?.id === item.id;

    gsap.to(ref.current, {
      uBW: disable ? 1 : isActive ? 0 : 1,
      uOpacity: disable ? 0 : isActive ? 0.85 : 0.5,
    });
    const scaleY = isActive ? 0.5 : 0.3;
    gsap.to(meshRef.current.position, {
      y: scaleY * 0.5,
    });
    gsap.to(meshRef.current.scale, {
      x: disable ? 0 : 1,
      y: scaleY,
      z: disable ? 0 : 1,
    });

    gsap.to(textRef.current.scale, {
      x: disable ? 0 : 1,
      y: disable ? 0 : 1,
      z: disable ? 0 : 1,
    });

    gsap.to(textRef.current.position, {
      y: scaleY * 1.05,
    });
    gsap.to(textRef.current.children[0], {
      fillOpacity: disable ? 0 : 1,
    });
    const groupScale = disable ? 0 : isActive ? 1.2 : 1;
    gsap.to(groupRef.current.scale, {
      x: groupScale,
      y: groupScale,
      z: groupScale,
    });
    meshRef.current.castShadow = !disable;
  }, [activeItem, item, disable]);

  return (
    <group rotation={[0, camera.rotation.y, 0]} ref={groupRef}>
      <group scale={[0, 0, 0]} rotation={[0, 0, Math.PI * 0.5]} ref={textRef}>
        <Text
          font="/HKGrotesk-Light.woff"
          color={sceneConfig.darkMode ? "#fff" : "#1e1e1e"}
          anchorX="left"
          fontSize={activeItem?.id === item.id ? 0.065 : 0.03}
          fillOpacity={0}
          anchorY="middle"
          material-toneMapped={false}
        >
          {`${item.index + 1}`.padStart(2, 0)}
          {": "}
          {item.label}
        </Text>
      </group>
      <mesh scale={[0, 0, 0]} rotation={[0, 0.5, 0]} ref={meshRef} castShadow>
        <boxGeometry args={[0.1, 1, 0.1, 1, 1, 1]} />
        <grainShaderMaterial
          ref={ref}
          uNoiseVariant={0.5}
          uColor={color}
          uLightPosition={[0.5, 2.5, 0.5]}
          transparent
        />
      </mesh>
    </group>
  );
};

export default HotspotStatue;
