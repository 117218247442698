import { Float, useGLTF } from "@react-three/drei";
import gsap from "gsap";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import TriangulatedMeshObject from "./TriangulatedMeshObject";
import TriMeshedObject from "./TriMeshedObject";

const GLBModel = ({
  controls,
  progress,
  model,
  disableScrolling,
  onComplete,
}) => {
  const { src, displayScale, loadScale } = model;
  const { scene } = useGLTF(src);

  const [baseGeometry, setBaseGeometry] = useState([]);

  const [animationProgress] = useState({ value: 1 });
  const [hasEnded, setHasEnded] = useState(false);

  // useEffect(() => {
  //   console.log("glb: mounted");
  //   return () => {
  //     console.log("glb: unmounted");
  //   };
  // }, []);
  useEffect(() => {
    if (scene) {
      setBaseGeometry([]);
      scene.traverse((child) => {
        if (child.isMesh) {
          let o = child.clone();
          let newGeo = o.geometry.toNonIndexed();
          newGeo.scale(loadScale, loadScale, loadScale);
          //   Note: Must scale!! -> it updates geometry.position which is crucial in aCenter calculation
          setBaseGeometry((prev) => [...prev, newGeo]);
        }
      });
    }
  }, [scene]);

  const onLoaded = useCallback(() => {
    // console.log("loaded");
  }, []);
  //   gsap.to(animationProgress, {
  //     value: 0,
  //     duration: 3,
  //     onStart: () => {
  //       setTimeout(() => {
  //         disableScrolling(false);
  //       }, 2000);
  //     },
  //   });
  // }, [animationProgress, disableScrolling]);

  return (
    <Float speed={2}>
      <group
        rotation={[0, progress * controls.speed * 5, 0]}
        scale={[displayScale, displayScale, displayScale]}
      >
        {baseGeometry.map((b) => (
          <TriMeshedObject
            // <TriangulatedMeshObject
            key={b.uuid}
            geometryObject={b}
            controls={controls}
            animationProgress={progress}
            // scroll={{ current: animationProgress.value }}
            // scroll={{ current: p }}
            // limit={1}
            onLoaded={onLoaded}
            // scroll={scroll}
            // limit={limit}
          />
        ))}
      </group>
    </Float>
  );
};
export default forwardRef((props, ref) => <GLBModel {...props} ref={ref} />);
