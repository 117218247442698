const data = {
  params: {
    filters: {
      film: {
        nIntensity: 0.05,
        sIntensity: 0.85,
        active: false,
      },
    },
  },

  locale: {
    ui: {
      drag: {
        it: "Trascina per iniziare",
        en: "Drag to explore",
      },
      close: {
        it: "Chiudi",
        en: "Close",
      },
      prev: {
        it: "Indietro",
        en: "Prev",
      },
      next: {
        it: "Avanti",
        en: "Next",
      },
      website: {
        it: "Sito del Mausoleo",
        en: "Mausoleum website",
      },
      chapter: {
        it: "Capitolo",
        en: "Chapter",
      },
    },
    intro: {
      h2: {
        it: "La Storia del",
        en: "The History of the",
      },
      h1: {
        it: "Mausoleo di Augusto",
        en: "Mausoleum of Augustus",
      },
      p: {
        it: "Un viaggio interattivo attraverso la storia di Roma",
        en: "An interactive journey through the history of Rome.",
      },
      cta: {
        it: "Inizia l' esperienza",
        en: "Start the experience",
      },
    },
    final: {
      h1: {
        it: "Stiamo costruendo il futuro",
        en: "We’re building the future",
      },
      cta: {
        it: "Riavvia l'esperienza",
        en: "Restart experience",
      },
    },
    cookie: {
      title: {
        it: "",
        en: "",
      },
      body: {
        it: "Gentile utente, nel nostro sito usiamo cookie propri e di terzi per migliorare i nostri servizi. se prosegui nella navigazione mediante accesso ad altra area del sito o selezione di un elemento dello stesso presterai il consenso all'uso dei cookie. per conoscere gli specifici cookie <a href='http://www.mausoleodiaugusto.it/it/privacy/' target='_blank'>clicca qui</a>",
        en: "Dear user, we use our own cookies and those of third parties on our website in order to improve our services. You will be providing your consent to the use of cookies if you continue browsing by accessing another area of the website or you select an element of same. To find out about specific cookies <a href='http://www.mausoleodiaugusto.it/en/privacy/' target='_blank'>click here</a>",
      },
      cta: {
        it: "Accetto",
        en: "Accept",
      },
    },
    fallback: {
      alert: {
        it: "Il computer non soddisfa i requisiti minimi per poter visualizzare la versione esperenziale di questo sito.",
        en: "It seems you don&#39;t have the minimum hardware requirements to run the experience version of our website.",
      },
      scroll: {
        it: "Scorri per navigare",
        en: "Scroll down to explore",
      },
      swipe: {
        it: "Swipe per navigare",
        en: "Swipe to explore",
      },
    },
  },

  book: [
    {
      key: "chapter-01",
      name: "01",
      background: "#f0dd80",
      title: "Augustus and<br>his good star",
      statue: {
        texture: "obj/chapter-01/chapter-01.jpg",
        mesh: "obj/chapter-01/chapter-01.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.03, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        maxDistance: 5,
        tiltAngle: -0.35,
        dollyZoom: 1.5,
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 101,
            position: {
              x: -0.1,
              y: 0.96,
              z: 0.64,
            },
            label: "Before the beginning",
          },
          {
            id: 102,
            position: {
              x: 0.54,
              y: 1.61,
              z: 0.35,
            },
            label: "The power of the name ",
          },
          {
            id: 103,
            position: {
              x: 0.51,
              y: 1.27,
              z: -0.41,
            },
            label: "Julius’ star",
          },
          {
            id: 104,
            position: {
              x: -0.24,
              y: 0.92,
              z: -0.59,
            },
            label: "The temple of the Star",
          },
          {
            id: 105,
            position: {
              x: -0.65,
              y: 1.19,
              z: -0.036,
            },
            label: "The emperor god",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-01/spline_intro.dae",
        scene: "obj/chapter-01/chapter-01.dae",
      },
      data: {},
    },
    {
      key: "chapter-02",
      name: "02",
      title: "The family<br>tomb",
      background: "#339194",
      statue: {
        texture: "obj/chapter-02/chapter-02.jpg",
        mesh: "obj/chapter-02/chapter-02.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.03, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        maxDistance: 5,
        tiltAngle: -0.27,
        dollyZoom: 1.1,
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 201,
            position: {
              x: 0.87,
              y: 0.96,
              z: 1.24,
            },
            label: "The conquest of power",
          },
          {
            id: 202,
            position: {
              x: 1.45,
              y: 1.61,
              z: 0.04,
            },
            label: "The Battle of Actium",
          },
          {
            id: 203,
            position: {
              x: 0.71,
              y: 1.27,
              z: -1.26,
            },
            label: "The images of power",
          },
          {
            id: 204,
            position: {
              x: -0.9,
              y: 0.56,
              z: -1.37,
            },
            label: "Augustus and the myth of Romulus ",
          },
          {
            id: 205,
            position: {
              x: -1.49,
              y: 1.33,
              z: -0.01,
            },
            label: "The Mausoleum",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-02/spline_intro.dae",
        scene: "obj/chapter-02/chapter-02.dae",
      },
      data: {},
    },
    {
      key: "chapter-03",
      name: "03",
      title: "The Campus<br>Martius",
      statue: {
        texture: "obj/chapter-03/chapter-03.jpg",
        mesh: "obj/chapter-03/chapter-03.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.03, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 301,
            position: {
              x: -0.15,
              y: 0.96,
              z: 0.98,
            },
            label: "The public space",
          },
          {
            id: 302,
            position: {
              x: 0.63,
              y: 1.61,
              z: 0.29,
            },
            label: "The Pantheon",
          },
          {
            id: 303,
            position: {
              x: 0.58,
              y: 0.85,
              z: -0.39,
            },
            label: "The Ara Pacis",
          },
          {
            id: 304,
            position: {
              x: -0.07,
              y: 0.91,
              z: -0.73,
            },
            label: "The clock",
          },
          {
            id: 305,
            position: {
              x: -0.69,
              y: 1.19,
              z: -0.36,
            },
            label: "Augustus’ house",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-03/spline_intro.dae",
        scene: "obj/chapter-03/chapter-03.dae",
      },
      data: {},
    },
    {
      key: "chapter-04",
      name: "04",
      title: "The death<br>of Augustus",
      statue: {
        texture: "obj/chapter-04/chapter-04.jpg",
        mesh: "obj/chapter-04/chapter-04.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.03, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 401,
            position: {
              x: -0.91,
              y: 0.66,
              z: 0.63,
            },
            label: "Death",
          },
          {
            id: 402,
            position: {
              x: -0.79,
              y: 0.88,
              z: -0.29,
            },
            label: "The journey of the mortal remains",
          },
          {
            id: 403,
            position: {
              x: 0,
              y: 1.05,
              z: -0.74,
            },
            label: "The will",
          },
          {
            id: 404,
            position: {
              x: 0.8,
              y: 0.91,
              z: -0.33,
            },
            label: "The Res Gestae",
          },
          {
            id: 405,
            position: {
              x: 1.0,
              y: 0.84,
              z: 0.18,
            },
            label: "The three funeral processions",
          },
          {
            id: 406,
            position: {
              x: 0.58,
              y: 1.07,
              z: 0.79,
            },
            label: "The cremation",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-04/spline_intro.dae",
        scene: "obj/chapter-04/chapter-04.dae",
      },
      data: {},
    },
    {
      key: "chapter-05",
      name: "05",
      title: "The golden<br>age of Rome",
      statue: {
        texture: "obj/chapter-05/chapter-05.jpg",
        mesh: "obj/chapter-05/chapter-05.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.02, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 501,
            position: {
              x: -1.45,
              y: 0.82,
              z: 0.76,
            },
            label: "True friends",
          },
          {
            id: 502,
            position: {
              x: -0.72,
              y: 0.7,
              z: -0.46,
            },
            label: "Greek education",
          },
          {
            id: 503,
            position: {
              x: 0.22,
              y: 0.53,
              z: -1.35,
            },
            label: "The transformation of Rome",
          },
          {
            id: 504,
            position: {
              x: 1.11,
              y: 1.16,
              z: -0.63,
            },
            label: "Res Publica Restituta",
          },
          {
            id: 505,
            position: {
              x: 1.36,
              y: 1.02,
              z: 0.65,
            },
            label: "The cultural renewal",
          },
          {
            id: 506,
            position: {
              x: -0.18,
              y: 1.19,
              z: 1.0,
            },
            label: "The circle of Maecenas",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-05/spline_intro.dae",
        scene: "obj/chapter-05/chapter-05.dae",
      },
      data: {},
    },
    {
      key: "chapter-06",
      name: "06",
      title: "The guests at<br>the Mausoleum",
      statue: {
        texture: "obj/chapter-06/chapter-06.jpg",
        mesh: "obj/chapter-06/chapter-06.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.03, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 601,
            position: {
              x: -0.93,
              y: 0.58,
              z: 0.67,
            },
            label: "The ashes in the Mausoleum",
          },
          {
            id: 602,
            position: {
              x: -0.61,
              y: 0.38,
              z: -0.11,
            },
            label: "The succession to the throne",
          },
          {
            id: 603,
            position: {
              x: -0.64,
              y: 0.84,
              z: -0.92,
            },
            label: "Augustus’ wives",
          },
          {
            id: 604,
            position: {
              x: 0.34,
              y: 1.16,
              z: -1.06,
            },
            label: "Julia's husbands",
          },
          {
            id: 605,
            position: {
              x: 0.57,
              y: 0.73,
              z: -0.42,
            },
            label: "The end of Julia",
          },
          {
            id: 606,
            position: {
              x: 1.1,
              y: 0.9,
              z: -0.07,
            },
            label: "La Gens Giulio-Claudia",
          },
          {
            id: 607,
            position: {
              x: 0.9,
              y: 0.62,
              z: 0.98,
            },
            label: "The other Emperors",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-06/spline_intro.dae",
        scene: "obj/chapter-06/chapter-06.dae",
      },
      data: {},
    },
    {
      key: "chapter-07",
      name: "07",
      title: "The last of the<br>roman tribunes",
      statue: {
        texture: "obj/chapter-07/chapter-07.jpg",
        mesh: "obj/chapter-07/chapter-07.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.03, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 701,
            position: {
              x: 0.19,
              y: 0.96,
              z: 1.09,
            },
            label: "From tombs to castles",
          },
          {
            id: 702,
            position: {
              x: 0.91,
              y: 1.61,
              z: 0.29,
            },
            label: "The Colonna family",
          },
          {
            id: 703,
            position: {
              x: 0.4,
              y: 0.61,
              z: -0.4,
            },
            label: "The wonders of Rome",
          },
          {
            id: 704,
            position: {
              x: -0.45,
              y: 0.91,
              z: -0.45,
            },
            label: "The commune of Rome",
          },
          {
            id: 705,
            position: {
              x: -0.72,
              y: 1.19,
              z: 0.26,
            },
            label: "The internal destruction",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-07/spline_intro.dae",
        scene: "obj/chapter-07/chapter-07.dae",
      },
      data: {},
    },
    {
      key: "chapter-08",
      name: "08",
      title: "The Soderini<br>Garden",
      statue: {
        texture: "obj/chapter-08/chapter-08.jpg",
        mesh: "obj/chapter-08/chapter-08.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.03, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 801,
            position: {
              x: -1.5,
              y: 0.96,
              z: 0.28,
            },
            label: "The port and the Leonine City",
          },
          {
            id: 802,
            position: {
              x: -1.08,
              y: 1.2,
              z: -1.31,
            },
            label: "The archaeological excavations",
          },
          {
            id: 803,
            position: {
              x: 0.56,
              y: 1.27,
              z: -1.43,
            },
            label: "The obelisks",
          },
          {
            id: 804,
            position: {
              x: 1.52,
              y: 0.7,
              z: -0.49,
            },
            label: "The Soderini Garden",
          },
          {
            id: 805,
            position: {
              x: 1.38,
              y: 1.19,
              z: 0.65,
            },
            label: "The Soderini family",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-08/spline_intro.dae",
        scene: "obj/chapter-08/chapter-08.dae",
      },
      data: {},
    },
    {
      key: "chapter-09",
      name: "09",
      title: "The Giostra<br>della Bufala",
      statue: {
        texture: "obj/chapter-09/chapter-09.jpg",
        mesh: "obj/chapter-09/chapter-09.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.03, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 901,
            position: {
              x: 1.4,
              y: 1.34,
              z: 0.54,
            },
            label: "The Fioravanti family",
          },
          {
            id: 902,
            position: {
              x: 1.42,
              y: 1.56,
              z: -0.79,
            },
            label: "The failure of the bullfight",
          },
          {
            id: 903,
            position: {
              x: 0.14,
              y: 1.28,
              z: -1.56,
            },
            label: "The success of the “Giostra”",
          },
          {
            id: 904,
            position: {
              x: -0.98,
              y: 1.16,
              z: -1.29,
            },
            label: "“Er corea”",
          },
          {
            id: 905,
            position: {
              x: -1.49,
              y: 0.94,
              z: -0.14,
            },
            label: "Excellent tourists",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-09/spline_intro.dae",
        scene: "obj/chapter-09/chapter-09.dae",
      },
      data: {},
    },
    {
      key: "chapter-10",
      name: "Chapter 10",
      title: "The Anfiteatro<br>Corea",
      statue: {
        texture: "obj/chapter-10/chapter-10.jpg",
        mesh: "obj/chapter-10/chapter-10.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.03, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 1001,
            position: {
              x: 1.24,
              y: 1.4,
              z: 1.02,
            },
            label: "Fochetti",
          },
          {
            id: 1002,
            position: {
              x: 1.56,
              y: 1.6,
              z: -0.05,
            },
            label: "Theatre",
          },
          {
            id: 1003,
            position: {
              x: 0.91,
              y: 1.27,
              z: -1.26,
            },
            label: "The first roof",
          },
          {
            id: 1004,
            position: {
              x: -0.08,
              y: 1.04,
              z: -1.56,
            },
            label: "“Respectable” Rome",
          },
          {
            id: 1005,
            position: {
              x: -1.06,
              y: 0.93,
              z: -1.24,
            },
            label: "From the Pope to the King",
          },
          {
            id: 1006,
            position: {
              x: -1.53,
              y: 1.05,
              z: -0.4,
            },
            label: "The statue of Victor Emmanuel",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-10/spline_intro.dae",
        scene: "obj/chapter-10/chapter-10.dae",
      },
      data: {},
    },
    {
      key: "chapter-11",
      name: "Chapter 11",
      title: "The Auditorium<br>Augusteo",
      statue: {
        texture: "obj/chapter-11/chapter-11.jpg",
        mesh: "obj/chapter-11/chapter-11.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.03, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 1101,
            position: {
              x: -1.38,
              y: 0.96,
              z: 0.64,
            },
            label: "Santa Cecilia",
          },
          {
            id: 1102,
            position: {
              x: 0,
              y: 1.61,
              z: -1.61,
            },
            label: "The première at the Augusteo",
          },
          {
            id: 1103,
            position: {
              x: 0.99,
              y: 0.9,
              z: -1.11,
            },
            label: "Toscanini and Wagner",
          },
          {
            id: 1104,
            position: {
              x: 1.48,
              y: 0.78,
              z: -0.28,
            },
            label: "Composers and conductors",
          },
          {
            id: 1105,
            position: {
              x: 1.35,
              y: 1.19,
              z: 0.63,
            },
            label: "The curtain goes down",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-11/spline_intro.dae",
        scene: "obj/chapter-11/chapter-11.dae",
      },
      data: {},
    },
    {
      key: "chapter-12",
      name: "Chapter 12",
      title: "Nostalgia for<br>Empire",
      statue: {
        texture: "obj/chapter-12/chapter-12.jpg",
        mesh: "obj/chapter-12/chapter-12.obj",
        expo: [
          [1.57, 1.57, 3.14, 3.14, 0, 0],
          [-4.71, -1.57, 4.71, -3.14, -3.14],
        ],
        noise: [0, 0, 0.5, 0.5, 0.03, 0],
        shader: "Statue_shader",
      },
      hotspots: {
        texture: "obj/hotspots/lightmap.png",
        alphamap: "obj/hotspots/alphamap.png",
        firetex: "obj/hotspots/firetex-r.png",
        mesh: "obj/hotspots/column.obj",
        shader: "Hotspots_shader",
        flames: "Flames_shader",
        items: [
          {
            id: 1201,
            position: {
              x: 1.3,
              y: 1.57,
              z: 0.54,
            },
            label: "Vast, orderly, powerful",
          },
          {
            id: 1202,
            position: {
              x: 1.08,
              y: 1.05,
              z: -0.92,
            },
            label: "Convincing the Romans",
          },
          {
            id: 1203,
            position: {
              x: -0.74,
              y: 0.81,
              z: -1.2,
            },
            label: "The pickaxe blow",
          },
          {
            id: 1204,
            position: {
              x: -1.43,
              y: 1.36,
              z: -0.25,
            },
            label: "Piazza Augusto Imperatore",
          },
        ],
      },
      splines: {
        intro: "obj/chapter-12/spline_intro.dae",
        scene: "obj/chapter-12/chapter-12.dae",
      },
      data: {},
    },
  ],
};
export default data;
