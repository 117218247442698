import { Navigate, Route, Routes } from "react-router-dom";
import CanvasTransitionTest from "./pages/CanvasTransitionTest";

function App() {
  return (
    <Routes>
      <Route path="/chapter/:id" element={<CanvasTransitionTest />} />
      <Route path="/" element={<Navigate to="/chapter/0" replace />} />
      {/* <Route path="/sky" element="<SkyLanding" /> */}
      {/* <Route path="/chapter/:id" element={<ChapterPage />} /> */}
    </Routes>
  );
}

export default App;
