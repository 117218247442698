import gsap from "gsap";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { radiusByMedia } from "../constants";
import data from "../data";

const SceneContext = React.createContext();

const models = [
  {
    src: "/models/converse-updated.glb",
    displayScale: 0.65,
    loadScale: 0.005,
  },
  {
    src: "/models/figure.glb",
    displayScale: 0.65,
    loadScale: 0.005,
  },
];

export const SceneProvider = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const chapter = useMemo(() => data.book[id], [id]);

  const model = useMemo(() => models[id], [id]);

  const [transitionStarted, setTransitionStarted] = useState(false);
  const [disableInteraction, setDisableInteraction] = useState(false);

  const [atLowerRange, setAtLowerRange] = useState(false);
  const [atHigherRange, setAtHighRange] = useState(false);

  const [animationProgress, setAnimationProgress] = useState({ value: 0 });

  const [config, setConfig] = useState({
    darkMode: false,
    progressRadius: window.matchMedia("(min-width: 768px)").matches
      ? radiusByMedia.desktop
      : radiusByMedia.mobile,
  });

  const sceneConfig = useMemo(
    () => ({
      ...config,
      background: config.darkMode ? "#111" : chapter.background,
      spotLightIntensity: config.darkMode ? 5 : 2,
      floorColor: config.darkMode ? "#111" : "#ffffff",
    }),
    [config, chapter]
  );

  const [activeStage, setActiveStage] = useState(0);

  // [Todo] Handle progress radius update on window resize

  // useEffect(() => {
  //   console.log("scene provider mounted");
  //   return () => {
  //     console.log("scene provider unmounted");
  //   };
  // }, []);

  const goToNext = useCallback(() => {
    const next = gsap.utils.wrap([0, 1], parseFloat(id) + 1);
    navigate(`/chapter/${next}`);

    setActiveStage(0);
  }, [id, navigate]);

  // component configuration
  const disableLineup = useMemo(
    () => transitionStarted || atLowerRange || atHigherRange,
    [transitionStarted, atLowerRange, atHigherRange]
  );

  // useEffect(() => {
  //   console.log("at start transitionStarted", transitionStarted);
  // }, [transitionStarted]);

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", ({ matches }) => {
        setConfig((prev) => ({
          ...prev,
          progressRadius: matches
            ? radiusByMedia.desktop
            : radiusByMedia.mobile,
        }));
      });
  }, []);

  const value = useMemo(
    () => ({
      sceneConfig,
      activeModel: model,
      activeChapter: chapter,
      activeStage,
      setActiveStage,
      goToNext,
      disableInteraction,
      setDisableInteraction,
      atLowerRange,
      setAtLowerRange,
      atHigherRange,
      setAtHighRange,
      disableLineup,
      transitionStarted,
      setTransitionStarted,
      setConfig,
      animationProgress,
      setAnimationProgress,
    }),
    [
      sceneConfig,
      chapter,
      activeStage,
      goToNext,
      model,
      disableInteraction,
      atLowerRange,
      atHigherRange,
      disableLineup,
      transitionStarted,
      animationProgress,
    ]
  );

  return (
    <SceneContext.Provider value={value}>{children}</SceneContext.Provider>
  );
};

export const useScene = () => React.useContext(SceneContext);
