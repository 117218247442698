import * as THREE from "three";
import { Html, PerspectiveCamera, SpotLight } from "@react-three/drei";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import GLBModel from "../components/GLBModel";
import {
  CombinedScrollerProvider,
  useCombinedScroller,
} from "../combinedScroller";
import gsap from "gsap";
import ProgressTrack from "../components/tracks/ProgressTrack";
import ContentLayout from "../components/ContentLayout";
import HotspotLineUp from "../components/models/HotspotLineup";
import { SceneProvider, useScene } from "../contexts/sceneContext";
import { rotateCamera } from "../utils";
import Header from "../components/Header";
import { retractionAnimationDuration } from "../timing";

const themeTextColor = "#fff";

const getCameraTrackPosition = (rotationY, radius) => {
  return {
    x: Math.sin(rotationY) * radius,
    z: Math.cos(rotationY) * radius,
  };
};

const ModelStage = ({ model, onComplete, moveCamera }) => {
  const { setDisable, scrollTo } = useCombinedScroller();
  const {
    transitionStarted,
    setTransitionStarted,
    atHigherRange,
    atLowerRange,
  } = useScene();
  const [animationProgress, setAnimationProgress] = useState({ value: 0 });
  const camera = useThree((state) => state.camera);

  const onEnter = useCallback(() => {
    setTransitionStarted(false);
    setDisable(false);
    // console.log("start animate animation progress to 0");
    let i = { value: 1 };
    setAnimationProgress(i);
    gsap.to(i, {
      value: 0,
      duration: 1.5,
      onUpdate: setAnimationProgress,
      onUpdateParams: [i],
    });
  }, [setDisable, setTransitionStarted]);

  const onExit = useCallback(() => {
    setTransitionStarted(true);
    setDisable(true);
    // console.log("start animate animation progress");
    let i = { value: 0 };
    setAnimationProgress(i);
    gsap.to(i, {
      value: 1,
      duration: 4,
      onUpdate: setAnimationProgress,
      delay: retractionAnimationDuration,
      onUpdateParams: [i],
      onStart: () => {
        setTimeout(() => {
          // console.log("at start 71");
          onComplete();
          const offset = -camera.rotation.y;
          rotateCamera(
            camera,
            (value) => {
              moveCamera({ y: value }, false);
            },
            offset,
            2.5
          );
          scrollTo((prev) => ({ ...prev, target: 0 }));
        }, retractionAnimationDuration * 1000.1);
      },
    });
  }, [onComplete, scrollTo, setDisable, setTransitionStarted]);

  useEffect(() => {
    // console.log("TRIGGER ON ENTER model stage: mounted");
    onEnter();
    return () => {
      // console.log("model stage: unmounted");
    };
  }, [onEnter]);

  useEffect(() => {
    if (atHigherRange && !transitionStarted) {
      // console.log("TRIGGER ON EXIT");
      onExit();
    } else if (atLowerRange && transitionStarted) {
      // console.log("TRIGGER ON ENTER");
      onEnter();
    }
  }, [atHigherRange, atLowerRange, transitionStarted, onEnter, onExit]);

  return (
    <GLBModel
      controls={{ speed: 2 }}
      progress={animationProgress.value}
      model={model}
    />
  );
};

const CanvasTransitionTest = () => {
  const {
    sceneConfig,
    activeChapter,
    activeModel,
    goToNext,
    activeStage,
    disableLineup,
    atLowerRange,
    atHigherRange,
  } = useScene();
  const { progress } = useCombinedScroller();
  const camera = useThree((state) => state.camera);
  const scene = useThree((state) => state.scene);
  const floorRef = useRef();

  const moveCamera = useCallback(
    (rotation, isActive, duration) => {
      const { tiltAngle, maxDistance, dollyZoom = 1 } = activeChapter.hotspots;

      const newCameraPosition = getCameraTrackPosition(rotation.y, maxDistance);
      const durationProps = { ...(duration && { duration: duration }) };
      const tl = gsap.timeline();
      const jobId = `${Math.random()}`;
      tl.add(jobId);
      tl.to(camera, { zoom: isActive ? dollyZoom : 1, ...durationProps }, jobId)
        .to(
          camera.position,
          {
            x: newCameraPosition.x,
            z: newCameraPosition.z,
            y: isActive ? dollyZoom : 0,
            ...durationProps,
          },
          jobId
        )
        .to(
          camera.rotation,
          {
            y: rotation.y,
            x: rotation.x !== undefined ? rotation.x : isActive ? tiltAngle : 0,
            ...durationProps,
          },
          jobId
        );
    },
    [camera, activeChapter]
  );

  const items = useMemo(
    () =>
      activeChapter.hotspots.items.map((i, idx) => ({
        ...i,
        index: idx,
      })),
    [activeChapter]
  );

  const onTransitionComplete = useCallback(() => {
    goToNext();
  }, [goToNext]);

  useFrame(() => {
    if (!disableLineup) {
      moveCamera(
        { y: progress * Math.PI * 2 },
        !atLowerRange && !atHigherRange
      );
    } else if (atLowerRange) {
      moveCamera({ y: progress * Math.PI * 2 }, false);
    }
  });

  useEffect(() => {
    // console.log("canvas: mounted");
    return () => {
      // console.log("canvas: unmounted");
    };
  }, []);

  useEffect(() => {
    const newColor = new THREE.Color(sceneConfig.background);
    const hex = {
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
    };
    if (!scene.background) {
      scene.background = newColor;
    }
    gsap.to(scene.background, {
      ...hex,
      duration: 0.75,
    });
    const newFloorColor = new THREE.Color(sceneConfig.floorColor);
    const floorColorHex = {
      r: newFloorColor.r,
      g: newFloorColor.g,
      b: newFloorColor.b,
    };
    if (floorRef.current) {
      gsap.to(floorRef.current.material.color, {
        ...floorColorHex,
        duration: 0.5,
      });
    }
  }, [sceneConfig.background, sceneConfig.floorColor, scene]);

  return (
    <>
      <PerspectiveCamera
        position={[0, 0, 5]}
        makeDefault
        rotation-order="YXZ"
        fov={35}
        near={0.001}
        zoom={1}
        far={1000}
      />
      <Suspense
        fallback={
          <Html>
            <div>Loading...</div>
          </Html>
        }
      >
        <SpotLight
          castShadow
          intensity={sceneConfig.spotLightIntensity}
          distance={5}
          angle={Math.PI / 5}
          penumbra={0.3}
          target-position={[0, 0, 0]}
          position={[0.5, 2.5, 0.5]}
          shadow-camera-near={1}
          shadow-camera-far={9}
          shadow-bias={0.0001}
          shadow-mapSize-height={2048}
          shadow-mapSize-width={2048}
          attenuation={5}
          anglePower={5}
        />

        <mesh
          rotation={[-Math.PI * 0.5, 0, 0]}
          scale={[2, 2, 2]}
          position={[0, -1, 0]}
          receiveShadow
          ref={floorRef}
        >
          <circleGeometry args={[1.2, 32]} />
          <meshPhysicalMaterial roughness={1} color={sceneConfig.floorColor} />
        </mesh>

        <ambientLight intensity={0.8} />
        <ModelStage
          model={activeModel}
          onComplete={onTransitionComplete}
          moveCamera={moveCamera}
        />
        <HotspotLineUp
          items={items}
          activeItem={activeStage}
          disable={disableLineup}
        />
      </Suspense>
      {/* <OrbitControls /> */}
    </>
  );
};
const Content = () => {
  const {
    sceneConfig,
    activeChapter,
    setActiveStage,
    goToNext,
    transitionStarted,
  } = useScene();

  useEffect(() => {
    // console.log("content: mounted");
    return () => {
      // console.log("content: unmounted");
    };
  }, []);

  return (
    <>
      <Header
        chapter={activeChapter}
        color={themeTextColor}
        goToNext={goToNext}
      />

      <Canvas shadows>
        <CanvasTransitionTest />
      </Canvas>

      <ContentLayout>
        <ProgressTrack
          updateDragTarget={() => {}}
          onStageChange={setActiveStage}
          chapter={activeChapter}
          radius={sceneConfig.progressRadius}
          transitionStarted={transitionStarted}
        />
      </ContentLayout>
    </>
  );
};
const CombinedScene = () => {
  const scrollRef = useRef();

  const {
    sceneConfig,
    atLowerRange,
    setAtLowerRange,
    atHigherRange,
    setAtHighRange,
  } = useScene();
  const limit = useMemo(() => sceneConfig.progressRadius, [sceneConfig]);

  const boundChecking = useMemo(() => {
    return [
      {
        condition: ({ current }) => !atLowerRange && current < 5,
        callback: (current) => {
          // console.log("at lower range!", current);
          setAtLowerRange(true);
        },
      },
      {
        condition: ({ current }) => current >= 5 && atLowerRange,
        callback: (current) => {
          // console.log("not at lower range anymore", current);
          setAtLowerRange(false);
        },
      },
      {
        condition: ({ current, limit }) =>
          current + 5 >= limit && !atHigherRange,
        callback: (current) => {
          // console.log("at high range!", current);
          setAtHighRange(true);
        },
      },
      {
        condition: ({ current, limit }) =>
          current + 5 <= limit && atHigherRange,
        callback: (current) => {
          // console.log("not at high range!", current);
          setAtHighRange(false);
        },
      },
    ];
  }, [atLowerRange, setAtLowerRange, atHigherRange, setAtHighRange]);

  useEffect(() => {
    // console.log("COMBIEND SCENE: mounted");
    return () => {
      // console.log("COMBIEND SCENE: unmounted");
    };
  }, []);
  return (
    <CombinedScrollerProvider
      containerRef={scrollRef}
      options={{
        limit: limit,
        disable: false,
        debug: false,
        boundChecking,
        mouseSensitivity: 1,
      }}
    >
      <Content />
    </CombinedScrollerProvider>
  );
};

const Scene = () => (
  <SceneProvider>
    <CombinedScene />
  </SceneProvider>
);
export default Scene;
